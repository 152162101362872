export const PODCAST_LIST_REQUEST = 'PODCAST_LIST_REQUEST'
export const PODCAST_LIST_SUCCESS = 'PODCAST_LIST_SUCCESS'
export const PODCAST_LIST_FAIL = 'PODCAST_LIST_FAIL'

export const PODCAST_DETAILS_REQUEST = 'PODCAST_DETAILS_REQUEST'
export const PODCAST_DETAILS_SUCCESS = 'PODCAST_DETAILS_SUCCESS'
export const PODCAST_DETAILS_FAIL = 'PODCAST_DETAILS_FAIL'

export const PODCAST_DELETE_REQUEST = 'PODCAST_DELETE_REQUEST'
export const PODCAST_DELETE_SUCCESS = 'PODCAST_DELETE_SUCCESS'
export const PODCAST_DELETE_FAIL = 'PODCAST_DELETE_FAIL'

export const PODCAST_CREATE_REQUEST = 'PODCAST_CREATE_REQUEST'
export const PODCAST_CREATE_SUCCESS = 'PODCAST_CREATE_SUCCESS'
export const PODCAST_CREATE_FAIL = 'PODCAST_CREATE_FAIL'
export const PODCAST_CREATE_RESET = 'PODCAST_CREATE_RESET'

export const PODCAST_UPDATE_REQUEST = 'PODCAST_UPDATE_REQUEST'
export const PODCAST_UPDATE_SUCCESS = 'PODCAST_UPDATE_SUCCESS'
export const PODCAST_UPDATE_FAIL = 'PODCAST_UPDATE_FAIL'
export const PODCAST_UPDATE_RESET = 'PODCAST_UPDATE_RESET'