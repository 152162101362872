export const RESOURCE_LIST_REQUEST = 'RESOURCE_LIST_REQUEST'
export const RESOURCE_LIST_SUCCESS = 'RESOURCE_LIST_SUCCESS'
export const RESOURCE_LIST_FAIL = 'RESOURCE_LIST_FAIL'

export const RESOURCE_DETAILS_REQUEST = 'RESOURCE_DETAILS_REQUEST'
export const RESOURCE_DETAILS_SUCCESS = 'RESOURCE_DETAILS_SUCCESS'
export const RESOURCE_DETAILS_FAIL = 'RESOURCE_DETAILS_FAIL'

export const RESOURCE_DELETE_REQUEST = 'RESOURCE_DELETE_REQUEST'
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS'
export const RESOURCE_DELETE_FAIL = 'RESOURCE_DELETE_FAIL'

export const RESOURCE_CREATE_REQUEST = 'RESOURCE_CREATE_REQUEST'
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS'
export const RESOURCE_CREATE_FAIL = 'RESOURCE_CREATE_FAIL'
export const RESOURCE_CREATE_RESET = 'RESOURCE_CREATE_RESET'

export const RESOURCE_UPDATE_REQUEST = 'RESOURCE_UPDATE_REQUEST'
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS'
export const RESOURCE_UPDATE_FAIL = 'RESOURCE_UPDATE_FAIL'
export const RESOURCE_UPDATE_RESET = 'RESOURCE_UPDATE_RESET'