export const TOPIC_LIST_REQUEST = 'TOPIC_LIST_REQUEST'
export const TOPIC_LIST_SUCCESS = 'TOPIC_LIST_SUCCESS'
export const TOPIC_LIST_FAIL = 'TOPIC_LIST_FAIL'

export const TOPIC_DETAILS_REQUEST = 'TOPIC_DETAILS_REQUEST'
export const TOPIC_DETAILS_SUCCESS = 'TOPIC_DETAILS_SUCCESS'
export const TOPIC_DETAILS_FAIL = 'TOPIC_DETAILS_FAIL'

export const TOPIC_DELETE_REQUEST = 'TOPIC_DELETE_REQUEST'
export const TOPIC_DELETE_SUCCESS = 'TOPIC_DELETE_SUCCESS'
export const TOPIC_DELETE_FAIL = 'TOPIC_DELETE_FAIL'

export const TOPIC_CREATE_REQUEST = 'TOPIC_CREATE_REQUEST'
export const TOPIC_CREATE_SUCCESS = 'TOPIC_CREATE_SUCCESS'
export const TOPIC_CREATE_FAIL = 'TOPIC_CREATE_FAIL'
export const TOPIC_CREATE_RESET = 'TOPIC_CREATE_RESET'

export const TOPIC_UPDATE_REQUEST = 'TOPIC_UPDATE_REQUEST'
export const TOPIC_UPDATE_SUCCESS = 'TOPIC_UPDATE_SUCCESS'
export const TOPIC_UPDATE_FAIL = 'TOPIC_UPDATE_FAIL'
export const TOPIC_UPDATE_RESET = 'TOPIC_UPDATE_RESET'

