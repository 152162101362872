export const FORUM_LIST_REQUEST = 'FORUM_LIST_REQUEST'
export const FORUM_LIST_SUCCESS = 'FORUM_LIST_SUCCESS'
export const FORUM_LIST_FAIL = 'FORUM_LIST_FAIL'

export const FORUM_DETAILS_REQUEST = 'FORUM_DETAILS_REQUEST'
export const FORUM_DETAILS_SUCCESS = 'FORUM_DETAILS_SUCCESS'
export const FORUM_DETAILS_FAIL = 'FORUM_DETAILS_FAIL'

export const FORUM_DELETE_REQUEST = 'FORUM_DELETE_REQUEST'
export const FORUM_DELETE_SUCCESS = 'FORUM_DELETE_SUCCESS'
export const FORUM_DELETE_FAIL = 'FORUM_DELETE_FAIL'

export const FORUM_CREATE_REQUEST = 'FORUM_CREATE_REQUEST'
export const FORUM_CREATE_SUCCESS = 'FORUM_CREATE_SUCCESS'
export const FORUM_CREATE_FAIL = 'FORUM_CREATE_FAIL'
export const FORUM_CREATE_RESET = 'FORUM_CREATE_RESET'

export const FORUM_UPDATE_REQUEST = 'FORUM_UPDATE_REQUEST'
export const FORUM_UPDATE_SUCCESS = 'FORUM_UPDATE_SUCCESS'
export const FORUM_UPDATE_FAIL = 'FORUM_UPDATE_FAIL'
export const FORUM_UPDATE_RESET = 'FORUM_UPDATE_RESET'
