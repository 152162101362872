export const RECORDING_LIST_REQUEST = 'RECORDING_LIST_REQUEST'
export const RECORDING_LIST_SUCCESS = 'RECORDING_LIST_SUCCESS'
export const RECORDING_LIST_FAIL = 'RECORDING_LIST_FAIL'

export const RECORDING_DETAILS_REQUEST = 'RECORDING_DETAILS_REQUEST'
export const RECORDING_DETAILS_SUCCESS = 'RECORDING_DETAILS_SUCCESS'
export const RECORDING_DETAILS_FAIL = 'RECORDING_DETAILS_FAIL'

export const RECORDING_DELETE_REQUEST = 'RECORDING_DELETE_REQUEST'
export const RECORDING_DELETE_SUCCESS = 'RECORDING_DELETE_SUCCESS'
export const RECORDING_DELETE_FAIL = 'RECORDING_DELETE_FAIL'

export const RECORDING_CREATE_REQUEST = 'RECORDING_CREATE_REQUEST'
export const RECORDING_CREATE_SUCCESS = 'RECORDING_CREATE_SUCCESS'
export const RECORDING_CREATE_FAIL = 'RECORDING_CREATE_FAIL'
export const RECORDING_CREATE_RESET = 'RECORDING_CREATE_RESET'

export const RECORDING_UPDATE_REQUEST = 'RECORDING_UPDATE_REQUEST'
export const RECORDING_UPDATE_SUCCESS = 'RECORDING_UPDATE_SUCCESS'
export const RECORDING_UPDATE_FAIL = 'RECORDING_UPDATE_FAIL'
export const RECORDING_UPDATE_RESET = 'RECORDING_UPDATE_RESET'

export const RECORDING_CATEGORY_REQUEST = 'RECORDING_CATEGORY_REQUEST'
export const RECORDING_CATEGORY_SUCCESS = 'RECORDING_CATEGORY_SUCCESS'
export const RECORDING_CATEGORY_FAIL = 'RECORDING_CATEGORY_FAIL'
