export const RADIO_LIST_REQUEST = 'RADIO_LIST_REQUEST'
export const RADIO_LIST_SUCCESS = 'RADIO_LIST_SUCCESS'
export const RADIO_LIST_FAIL = 'RADIO_LIST_FAIL'

export const RADIO_DETAILS_REQUEST = 'RADIO_DETAILS_REQUEST'
export const RADIO_DETAILS_SUCCESS = 'RADIO_DETAILS_SUCCESS'
export const RADIO_DETAILS_FAIL = 'RADIO_DETAILS_FAIL'

export const RADIO_DELETE_REQUEST = 'RADIO_DELETE_REQUEST'
export const RADIO_DELETE_SUCCESS = 'RADIO_DELETE_SUCCESS'
export const RADIO_DELETE_FAIL = 'RADIO_DELETE_FAIL'

export const RADIO_CREATE_REQUEST = 'RADIO_CREATE_REQUEST'
export const RADIO_CREATE_SUCCESS = 'RADIO_CREATE_SUCCESS'
export const RADIO_CREATE_FAIL = 'RADIO_CREATE_FAIL'
export const RADIO_CREATE_RESET = 'RADIO_CREATE_RESET'

export const RADIO_UPDATE_REQUEST = 'RADIO_UPDATE_REQUEST'
export const RADIO_UPDATE_SUCCESS = 'RADIO_UPDATE_SUCCESS'
export const RADIO_UPDATE_FAIL = 'RADIO_UPDATE_FAIL'
export const RADIO_UPDATE_RESET = 'RADIO_UPDATE_RESET'

