import React, { useEffect} from 'react';
import { Card, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Rating from './Rating';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import { listProfiles } from '../actions/profileActions'

function Blog({ blog, handleToggle, toggle }) {

  const id = blog._id
  const dispatch = useDispatch()

  const profileList = useSelector(state => state.profileList)
  const { profiles } = profileList
    
  let keyword = useLocation().search

  useEffect(() => {
    dispatch(listProfiles(keyword))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [dispatch, keyword])

  return (

    <Card className='mb-3 p-3 rounded card-img-top colorcrd border border-success border-2 h-70 roundedCard' key={blog._id} >
        
      {blog.image && (
      <Card.Header>
          <Card.Img src={blog.image} className="card-img-top my-2 img-fluid h-50 w-50" alt="Imagen" />
      </Card.Header>
      )}
        <Card.Body>
          <Link to={`/blogs/${blog._id}`}>
            <h5 style={{ fontFamily: 'rm_typerighter'}}><strong>{blog.name}</strong> </h5>
          </Link>
          <h6 className='text-info' style={{ fontFamily: 'rm_typerighter'}}><b>{blog.comment}</b></h6>
        </Card.Body> 

      <Card.Footer>
      <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
      <div class="p-2 bd-highlight align-items-center "><h6 className='text-center fontproject' style={{ fontFamily: 'rm_typerighter'}}><b>Creado por: {blog.author}</b></h6></div>
        {profiles.map(profile => (
          profile.name.trim() === blog.author.trim() ? <div class="p-2 bd-highlight"><Image className='rounded-circle' src={profile.image} style={{ width: 65, maxHeight: 50}}/></div> : ''     
        ))}
      <div class="p-2 bd-highlight align-items-center"><h6 className='text-center fontproject' style={{ fontFamily: 'rm_typerighter'}}><b><Moment format="DD/MM/YYYY hh:mm:ss">{blog.createdAt}</Moment></b></h6></div>
      </div>
      </Card.Footer>  
    </Card>
  )
}

export default Blog
